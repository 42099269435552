import { HttpInterceptorFn } from '@angular/common/http';
import { AuthService } from '../services/auth.service';
import { inject } from '@angular/core';
import { TokenService } from '../services/token.service';
import { catchError, throwError } from 'rxjs';
import { Router } from '@angular/router';

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  const tokenService = inject(TokenService);
  const jwtToken = tokenService.getToken();
  const entraToken = tokenService.getTokenEntra() || "";
  const authService = inject(AuthService);
  const router = inject(Router);

  if (jwtToken) {
    
    const bearer = `Bearer ${jwtToken}`;

    req = req.clone({
      setHeaders: {
        Authorization: bearer,
        "X-Entra-Token": entraToken,
      },
    });
  }

  const currentFacility = authService.getCurrentFacility();
  if (currentFacility) {
    req = req.clone({
      setHeaders: {
        FacilityID: currentFacility,
      },
    });
  }

  return next(req).pipe(
    catchError((err) => {
      if (err.status === 401) {
        router.navigateByUrl('/login');
      }
      return throwError(() => err);
    })
  );
};
